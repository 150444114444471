import { LoaderFunctionArgs } from "react-router-dom";
import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { ShippingRoute, ShippingState } from "utils/types/shipping";

export const statesLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const envProps = await getEnvProps();
    const headers = await getHeaders();

    try {
      const req = await fetch(
          `${envProps.base_url}/api/v1/admin/state?country_id=${id}`,
          {
            headers,
          }
        ),
        res = await req.json();

      if (res.success != null && res.success) {
        return res.data as ShippingState[];
      } else {
        throw new Error("Error 404, State not found.");
      }
    } catch (error) {
      console.error("Error fetching State:", error);
      // throw new Error("Error fetching ticket");
    }
  }
  return null;
};

export const citiesLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const envProps = await getEnvProps();
    const headers = await getHeaders();

    try {
      const req = await fetch(
          `${envProps.base_url}/api/v1/admin/city?state_id=${id}`,
          {
            headers,
          }
        ),
        res = await req.json();

      if (res.success != null && res.success) {
        return res.data as ShippingState[];
      } else {
        throw new Error("Error 404, Cities not found.");
      }
    } catch (error) {
      console.error("Error fetching Cities:", error);
      // throw new Error("Error fetching ticket");
    }
  }
  return null;
};

export const routesLoader = async () => {
  const envProps = await getEnvProps();
  const headers = await getHeaders();

  try {
    const req = await fetch(`${envProps.base_url}/api/v1/admin/shippingRoute`, {
        headers,
      }),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data as ShippingRoute[];
    } else {
      throw new Error("Error 404, Routes not found.");
    }
  } catch (error) {
    console.error("Error fetching Routes:", error);
    throw new Error("Error fetching Routes");
  }
  return null;
};
