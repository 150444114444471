import { takeLatest, all, call, put } from "typed-redux-saga";
import { USERS_ACTION_TYPES } from "./users.types";
import { apiRequest } from "lib/api/apiClient";
import { usersActions } from "./users.slice";

export function* getCustomers() {
  try {
    const link = "api/v1/admin/customers";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(usersActions.setCustomers(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* getVendors() {
  try {
    const link = "/api/v1/admin/vendors";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(usersActions.setVendors(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* getUsers() {
  try {
    const link = "api/v1/admin/users";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(usersActions.setUsers(data));
  } catch (error) {
    console.log(error as Error);
  }
}

function* onFetchCustomers() {
  yield* takeLatest(USERS_ACTION_TYPES.FETCH_CUSTOMERS, getCustomers);
  yield* takeLatest(USERS_ACTION_TYPES.FETCH_CUSTOMERS, getUsers);
}
function* onFetchVendors() {
  yield* takeLatest(USERS_ACTION_TYPES.FETCH_VENDORS, getVendors);
}
export function* usersSagas() {
  yield* all([call(onFetchCustomers), call(onFetchVendors)]);
}
