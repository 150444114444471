import { alertMessage } from "components/toolkit/initial-state.component";
import { Category } from "../types/category";
import { SelectGroupedOption } from "../types/app";
import { User } from "../types/user";
import { ProductItem } from "../types/products";
import { Ticket } from "utils/types/ticket";
import { Brand } from "utils/types/brand";

export const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
  timeZone: "America/Los_Angeles",
});
export function timeAgo(timestamp: string): string {
  const now = new Date();
  const past = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const timeIntervals = {
    year: 60 * 60 * 24 * 365,
    month: 60 * 60 * 24 * 30,
    week: 60 * 60 * 24 * 7,
    day: 60 * 60 * 24,
    hour: 60 * 60,
    minute: 60,
    second: 1,
  };

  for (const [unit, secondsInUnit] of Object.entries(timeIntervals)) {
    const interval = Math.floor(diffInSeconds / secondsInUnit);
    if (interval >= 1) {
      return interval === 1 ? `1 ${unit} ago` : `${interval} ${unit}s ago`;
    }
  }

  return "Just now";
}

export const getGreeting = (name: string) => {
    var nowTime = new Date();
    const hour = nowTime.getHours(),
      firstName = extractFirstName(name);

    //   console.log(nowTime);
    if (hour >= 20) {
      return {
        greeting: `Good Night ${firstName}`,
        message: `Have a good night rest.`,
      };
    } else if (hour > 17) {
      return {
        greeting: `Good Evening ${firstName}`,
        message: `Hope you enjoyed your day?`,
      };
    } else if (hour > 11) {
      return {
        greeting: `Good Afternoon ${firstName}`,
        message: `How is your day going?`,
      };
    } else if (hour < 12) {
      return {
        greeting: `Good Morning ${firstName}`,
        message: `How was your night?`,
      };
    }

    return {
      greeting: `Good Day ${firstName}`,
      message: `Hope you are doing great?`,
    };
  },
  extractFirstName = (name: string): string => {
    // Split the name by spaces
    const nameParts = name.split(" ");

    // Define an array of titles
    const titles = [
      "Prof.",
      "Mr.",
      "Mrs.",
      "Dr.",
      "Ms.",
      "Miss",
      "Sir",
      "Madam",
    ];

    // Loop through each part of the name
    for (const part of nameParts) {
      // If the part is not a title, return it as the first name
      if (!titles.includes(part)) {
        return part;
      }
    }

    // If no name part is found, return an empty string
    return "";
  },
  truncateStr = (str = "", MAX = 20) => {
    const truncatedStr = str.substring(0, MAX);
    return str.length > MAX ? truncatedStr + "..." : truncatedStr;
  };
export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    alertMessage("success", `${text} copied to clipboard!`);
  } catch (err) {
    alertMessage("error", "Failed to copy .");
  }
};
export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const makeId = (length = 12) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789JKLMNOPQRSTUVWXYZ0123456789JKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  classNames = (...classes: any[]) => {
    return classes.filter(Boolean).join(" ");
  };

export enum Language {
  Empty = "*",
}
export enum AnimateType {
  SlideInLeft = "slideInLeft",
  SlideInRight = "slideInRight",
  SlideInTop = "slideInTop",
  SlideInDown = "slideInDown",
  FadeInLeft = "fadeInLeft",
  FadeInRight = "fadeInRight",
  FadeInUp = "fadeInUp",
  FadeInDown = "fadeInDown",
  BounceIn = "bounceIn",
  ZoomIn = "zoomIn",
}

export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
export const animate_animation = (animate: AnimateType) => {
    switch (animate) {
      case AnimateType.FadeInLeft:
        return {
          hidden: { opacity: 0, x: -75 },
          visible: { opacity: 1, x: 0 },
        };
      case AnimateType.FadeInRight:
        return {
          hidden: { opacity: 0, x: 75 },
          visible: { opacity: 1, x: 0 },
        };
      case AnimateType.FadeInDown:
        return {
          hidden: { opacity: 0, y: -75 },
          visible: { opacity: 1, y: 0 },
        };
      case AnimateType.FadeInUp:
        return {
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        };
      default:
        return {
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        };
    }
  },
  generateCombinations = (
    countries: string[],
    currencies: string[]
  ): { country: string; currency: string }[] => {
    const combinations: { country: string; currency: string }[] = [];

    for (const country of countries) {
      for (const currency of currencies) {
        combinations.push({ country, currency });
      }
    }

    return combinations;
  },
  getFirstLetter = (str: string): string => {
    if (str.length === 0) {
      throw new Error("String is empty");
    }
    return str.charAt(0);
  },
  arrayToString = (numbers: any[]): string => {
    return numbers.join(",");
  },
  stringToArray = (str: string): number[] => {
    return str.split(",").map(Number);
  },
  getClientSideCookie = (name: string): string | undefined => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`))
      ?.split("=")[1];

    return cookieValue != null ? decodeURIComponent(cookieValue) : undefined;
  },
  removeCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  },
  isDoneWithRegistration = (user: User) =>
    user.address == null && user.addresses.length === 0;
export function saveToLocalStorageProtected(key: string, value: object): void {
  if (typeof window !== "undefined") {
    const serializedValue = JSON.stringify(value);
    const encodedValue = btoa(serializedValue);
    localStorage.setItem(key, encodedValue);
  }
}

export function getFromLocalStorageProtected<T>(key: string): T | null {
  if (typeof window !== "undefined") {
    const encodedValue = localStorage.getItem(key);
    if (encodedValue) {
      const serializedValue = atob(encodedValue);
      return JSON.parse(serializedValue) as T;
    }
  }
  return null;
}
export function saveToCookieProtected(
  key: string,
  value: object,
  days: number = 7
): void {
  if (typeof window !== "undefined") {
    const serializedValue = JSON.stringify(value);
    const encodedValue = btoa(serializedValue);

    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "; expires=" + date.toUTCString();
    if (process.env.NODE_ENV !== "production") {
      document.cookie = `${key}=${encodedValue};`;
    } else {
      document.cookie = `${key}=${encodedValue}; path=/; domain=.cusorcart.com${expires}`;
    }
    // Ensure the domain is set correctly to share the cookie across subdomains
  }
}

export function getFromCookieProtected<T>(key: string): T | null {
  if (typeof window !== "undefined") {
    const name = key + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        const encodedValue = cookie.substring(name.length, cookie.length);

        // Check if the cookie value is not empty or invalid
        if (encodedValue) {
          try {
            const serializedValue = atob(encodedValue);
            return JSON.parse(serializedValue) as T;
          } catch (e) {
            console.error("Error parsing cookie:", e);
            return null; // Return null if parsing fails
          }
        }
      }
    }
  }
  return null;
}
export function removeFromCookieProtected(key: string): void {
  if (typeof window !== "undefined") {
    // Setting the cookie with a past expiration date to remove it
    if (process.env.NODE_ENV !== "production") {
      document.cookie = `${key}=;`;
    } else {
      document.cookie = `${key}=; path=/; domain=.cusorcart.com; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }
  }
}

export const isURL = (url: string): boolean => {
  // Regular expression to match a URL
  const urlPattern = new RegExp(
    "^(https?://)?" + // protocol (optional)
      "((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])?)\\.)+[a-zA-Z]{2,})" + // domain name
      "(:\\d{1,5})?" + // port
      "(/[-a-zA-Z\\d%_.~+]*)*" + // path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(#[-a-zA-Z\\d_]*)?$",
    "i"
  ); // fragment locator
  return urlPattern.test(url);
};
export function buildCategoryTree(categories: Category[]): Category[] {
  const categoryMap: { [key: number]: Category } = {};
  const tree: Category[] = [];

  // Create new objects for each category to make them extensible
  categories.forEach((category) => {
    categoryMap[category.id] = { ...category, children: [] };
  });

  // Build the tree structure
  categories.forEach((category) => {
    const extendedCategory = categoryMap[category.id];
    if (category.parent_id === null || category.parent_id === undefined) {
      // If no parent_id, it is a root category
      tree.push(extendedCategory);
    } else {
      // If it has a parent_id, add it to the parent's children
      const parentCategory = categoryMap[category.parent_id];
      if (parentCategory) {
        parentCategory.children!.push(extendedCategory);
      }
    }
  });

  return tree;
}

export function mapCategoryTreeToOptions(
  categoryTree: Category[]
): SelectGroupedOption[] {
  const groupedOptions: SelectGroupedOption[] = [];

  categoryTree.forEach((category) => {
    const group: SelectGroupedOption = {
      label: category.name,
      options: [{ label: category.name, value: category.id }],
    };

    if (category.children && category.children.length > 0) {
      category.children.forEach((child) => {
        group.options.push({ label: child.name, value: child.id });
        if (child.children && child.children.length > 0) {
          child.children.forEach((subChild) => {
            group.options.push({
              label: `${child.name} → ${subChild.name}`,
              value: subChild.id,
            });
          });
        }
      });
    }

    groupedOptions.push(group);
  });

  return groupedOptions;
}
export const sortProducts = (products: ProductItem[], criteria: string) => {
  if (criteria === "date_asc") {
    return products.sort(
      (a, b) => new Date(a.added).getTime() - new Date(b.added).getTime()
    );
  } else if (criteria === "date_desc") {
    return products.sort(
      (a, b) => new Date(b.added).getTime() - new Date(a.added).getTime()
    );
  } else if (criteria === "name_asc") {
    return products.sort((a, b) => a.name.localeCompare(b.name));
  } else if (criteria === "name_desc") {
    return products.sort((a, b) => b.name.localeCompare(a.name));
  } else if (criteria === "price_asc") {
    return products.sort((a, b) => a.price - b.price);
  } else if (criteria === "price_desc") {
    return products.sort((a, b) => b.price - a.price);
  } else if (criteria === "stock_asc") {
    return products.sort((a, b) => a.stock - b.stock);
  } else if (criteria === "stock_desc") {
    return products.sort((a, b) => b.stock - a.stock);
  }
  return products;
};

export const sortTickets = (tickets: Ticket[], criteria: string) => {
  if (criteria === "date_asc") {
    return tickets.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
  } else if (criteria === "date_desc") {
    return tickets.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  } else if (criteria === "name_asc") {
    return tickets.sort((a, b) => a.code.localeCompare(b.code));
  } else if (criteria === "name_desc") {
    return tickets.sort((a, b) => b.code.localeCompare(a.code));
  }
  return tickets;
};
export const sortCategories = (category: Category[], criteria: string) => {
  if (criteria === "date_asc") {
    return category.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
  } else if (criteria === "date_desc") {
    return category.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  } else if (criteria === "name_asc") {
    return category.sort((a, b) => a.name.localeCompare(b.name));
  } else if (criteria === "name_desc") {
    return category.sort((a, b) => b.name.localeCompare(a.name));
  }
  return category;
};
export const sortBrands = (category: Brand[], criteria: string) => {
  if (criteria === "date_asc") {
    return category.sort(
      (a, b) =>
        new Date(a.created_at ?? "0").getTime() -
        new Date(b.created_at ?? "0").getTime()
    );
  } else if (criteria === "date_desc") {
    return category.sort(
      (a, b) =>
        new Date(b.created_at ?? "0").getTime() -
        new Date(a.created_at ?? "0").getTime()
    );
  } else if (criteria === "name_asc") {
    return category.sort((a, b) => a.name.localeCompare(b.name));
  } else if (criteria === "name_desc") {
    return category.sort((a, b) => b.name.localeCompare(a.name));
  }
  return category;
};

export const formatProductDate = (date: string | number | Date): string => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return `${day} ${month} ${year}`;
};

export function stripTagsAndLimit(str: string, limit: number = 160): string {
  // Use a regular expression to remove HTML tags
  const strippedStr = str.replace(/<\/?[^>]+(>|$)/g, "");
  // Limit the result to the specified number of characters
  return strippedStr.length > limit
    ? strippedStr.substring(0, limit)
    : strippedStr;
}
export function extractUserData(jsonData: any): User {
  const user: User = {
    id: jsonData.id,
    type: jsonData.type,
    name: jsonData.name,
    email: jsonData.email,
    avatar: jsonData.avatar ?? null,
    remember_token: jsonData.remember_token,
    phone_number: jsonData.phone_number ?? null,
    referral_code: jsonData.referral_code ?? null,
    referral_bonus: jsonData.referral_bonus,
    referred_by: jsonData.referred_by ?? null,
    email_verified: jsonData.email_verified,
    is_banned: jsonData.is_banned,
    addresses: Array.isArray(jsonData.addresses) ? jsonData.addresses : [],
    address: jsonData.address ?? null,
    role: jsonData.role
      ? {
          id: jsonData.role.id,
          name: jsonData.role.name,
          permissions: Array.isArray(jsonData.role.permissions)
            ? jsonData.role.permissions
            : [],
        }
      : undefined,
    emails: Array.isArray(jsonData.emails)
      ? jsonData.emails.map((email: any) => ({
          id: email.id,
          email: email.email,
        }))
      : undefined,
  };

  return user;
}

export const formatDateTime = (isoDate: string | number | Date): string => {
  const dateObj = new Date(isoDate);

  // Check if the date is valid
  if (isNaN(dateObj.getTime())) {
    return "Invalid Date";
  }

  // Extract month, day, year
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString().slice(-2);

  // Extract hours and minutes, convert to 12-hour format
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // If hour is 0, make it 12 (for 12AM and 12PM)

  // Format time as hh:mmAM/PM
  const formattedTime = `${hours}:${minutes}${ampm}`;

  // Return the formatted date and time
  return `${month}/${day}/${year} ${formattedTime}`;
};
