import { createSelector } from "reselect";
import { RootState } from "../store";

const selectUsersReducer = (state: RootState) => state.users;

export const selectVendors = createSelector(
  [selectUsersReducer],
  (users) => users.vendors
);
export const selectCustomers = createSelector(
  [selectUsersReducer],
  (users) => users.customers
);
export const selectAllUsers = createSelector(
  [selectUsersReducer],
  (users) => users.all
);
// export const selectWhatsapp = createSelector([selectDataReducer], (data) => {
//   const socials = data.info == null ? null : data.info.socials,
//     whatsapp = socials?.find((social) => social.name === "Whatsapp");
//   return whatsapp;
// });
