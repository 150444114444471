import { overviewOptions } from "utils/helper/states";
import { Country } from "utils/types/address";
import {
  AppSettings,
  Currencies,
  IpInfo,
  OverviewData,
  SiteSettings,
} from "utils/types/app";
import { createSlice } from "@reduxjs/toolkit";

export type SearchItem = {
  title: string;
  uri: string;
};

export type DataState = {
  readonly app_settings: AppSettings | null;
  readonly site_settings: SiteSettings | null;
  readonly location: IpInfo | null;
  readonly isLoading: boolean;
  readonly isSidebarSm: boolean;
  readonly overviewFilter: string;
  readonly overview: OverviewData;
  readonly search_history: SearchItem[] | null;
  readonly currencies: Currencies | null;
  readonly countries: Country[] | null;
  readonly searchValue: string;
};

const INITIAL_STATE: DataState = {
  app_settings: null,
  site_settings: null,
  location: null,
  overviewFilter: overviewOptions[0].value,
  overview: {
    customers: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    staff: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    vendor: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    subscribers: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    products: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    categories: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    brands: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
    orders: {
      total: 0,
      change: 0,
      change_percent: 0,
      previous: 0,
    },
  },
  isLoading: false,
  search_history: null,
  isSidebarSm: false,
  currencies: null,
  countries: null,
  searchValue: "",
};
const dataSlice = createSlice({
  name: "data",
  initialState: INITIAL_STATE,
  reducers: {
    setAppSettings(state, action) {
      state.app_settings = action.payload;
    },
    setSiteSettings(state, action) {
      state.site_settings = action.payload;
    },
    setUserLocation(state, action) {
      state.location = action.payload;
    },
    setOverviewOption(state, action) {
      state.overviewFilter = action.payload;
    },
    setOverview(state, action) {
      state.overview = action.payload;
    },
    setToggleSideBarSm(state) {
      state.isSidebarSm = !state.isSidebarSm;
    },
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setDataSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    addToSearchHistory(state, action) {
      const newSearchItem = action.payload;
      state.search_history =
        state.search_history?.filter(
          (item) => item.title !== newSearchItem.title
        ) || [];
      state.search_history.push(newSearchItem);
      if (state.search_history.length > 3) {
        state.search_history.shift();
      }
    },
  },
});

export const {
  setAppSettings,
  setSiteSettings,
  setUserLocation,
  setOverviewOption,
  setOverview,
  addToSearchHistory,
  setToggleSideBarSm,
  setCurrencies,
  setCountries,
  setDataSearchValue,
} = dataSlice.actions;
export default dataSlice;
