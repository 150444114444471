import { createSlice } from "@reduxjs/toolkit";
import { PickupStation } from "utils/types/app";
import {
  DeliveryZone,
  ShippingCity,
  ShippingCountry,
  ShippingRoute,
  ShippingState,
} from "utils/types/shipping";

export type ShippingStateType = {
  readonly countries: ShippingCountry[] | null;
  readonly states: ShippingState[] | null;
  readonly cities: ShippingCity[] | null;
  readonly pickupStations: PickupStation[] | null;
  readonly deliveryZones: DeliveryZone[] | null;
  readonly shippingRoutes: ShippingRoute[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: ShippingStateType = {
  countries: null,
  states: null,
  cities: null,
  pickupStations: null,
  deliveryZones: null,
  shippingRoutes: null,
  searchValue: "",
  loading: false,
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState: INITIAL_STATE,
  reducers: {
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setStates(state, action) {
      state.states = action.payload;
    },
    setCities(state, action) {
      state.cities = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setStations(state, action) {
      state.pickupStations = action.payload;
    },
    setZones(state, action) {
      state.deliveryZones = action.payload;
    },
    setRoutes(state, action) {
      state.shippingRoutes = action.payload;
    },
  },
});

export const shippingActions = shippingSlice.actions;
export default shippingSlice;
