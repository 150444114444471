import { Brands } from "utils/types/brand";
import { createSlice } from "@reduxjs/toolkit";

export type BrandState = {
  readonly all: Brands | null;
  searchValue: "";
  loading: boolean;
  start_date: string | null;
  end_date: string | null;
  sortOption: string;
  limit: number;
};

const INITIAL_STATE: BrandState = {
  all: null,
  searchValue: "",
  loading: false,
  start_date: null,
  end_date: null,
  sortOption: "date_desc",
  limit: 10,
};
const brandSlice = createSlice({
  name: "brand",
  initialState: INITIAL_STATE,
  reducers: {
    setAllBrand(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setStartDate(state, action) {
      state.start_date = action.payload;
    },
    setEndDate(state, action) {
      state.end_date = action.payload;
    },
    setSortOption(state, action) {
      state.sortOption = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
  },
});

export const brandActions = brandSlice.actions;
export default brandSlice;
